import React from 'react'
import { css, cx } from 'emotion'
import Helmet from 'react-helmet'
import { siteName } from '../data'

import Layout from '../components/layout'
import Heading from '../components/small/heading'
import BtnLink from '../components/small/btn-link'

import accountancyPic from '../images/accountancy.png'
import consultancyPic from '../images/consultancy.png'
import taxationPic from '../images/taxation.png'

const breakPoint = 650

const SectionWrap = css`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPoint}px) {
    flex-direction: row;
  }
`

const sectionStyles = css`
  background-repeat: no-repeat;

  @media (max-width: ${breakPoint - 1}px) {
    margin-bottom: 20px;
    background-position: right top;
    padding-right: 100px;
  }

  @media (min-width: ${breakPoint}px) {
    background-position: 20% top;
    padding-top: 80px;
    width: 30%;
  }
`

const sectionFirstStyles = css`
  background-image: url(${accountancyPic});
`

const sectionMiddleStyles = css`
  background-image: url(${taxationPic});

  @media (min-width: ${breakPoint}px) {
    //margin: 0 70px;
  }
`

const sectionLastStyles = css`
  background-image: url(${consultancyPic});
`

const subHeadingStyles = css`
  font-size: 22px;
`

const ulStyles = css`
  font-size: 14px;
  list-style-type: disc;
  list-style-position: inside;
  line-height: 1.4;
  margin-top: 10px;
`

const ServicesPage = () => (
  <Layout>
    <Helmet title={`Services - ${siteName}`} />
    <Heading>Services</Heading>

    <div className={SectionWrap}>
      <section className={cx(sectionStyles, sectionFirstStyles)}>
        <h3 className={subHeadingStyles}>Accountancy</h3>
        <ul className={ulStyles}>
          <li>Sole trader accounts</li>
          <li>Partnership accounts</li>
          <li>LLP accounts</li>
          <li>Company accounts</li>
        </ul>
      </section>

      <section className={cx(sectionStyles, sectionMiddleStyles)}>
        <h3 className={subHeadingStyles}>Taxation</h3>
        <ul className={ulStyles}>
          <li>Personal tax returns</li>
          <li>Corporate tax</li>
          <li>VAT returns</li>
          <li>Company tax returns</li>
          <li>Annual returns</li>
        </ul>
      </section>

      <section className={cx(sectionStyles, sectionLastStyles)}>
        <h3 className={subHeadingStyles}>Consultancy</h3>
        <ul className={ulStyles}>
          <li>Advice on business start ups</li>
          <li>Incorporation and registration of limited companies</li>
          <li>
            Advice on best trading vehicles, e.g. sole trader, partnerships, LLP
            or company
          </li>
          <li>Ad hoc tax planning</li>
          <li>General business advice</li>
          <li>General personal tax advice</li>
        </ul>
      </section>
    </div>

    <div style={{ marginTop: '20px' }}>
      <p>
        <BtnLink to="/contact">Contact me for more info</BtnLink>
      </p>
    </div>
  </Layout>
)

export default ServicesPage
