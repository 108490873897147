import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'

const BtnLink = props => {
  const cont = css`
    color: #f6f6f6;
    background: #00ac82;
    background-image: linear-gradient(#02ad85, #019975);
    padding: 12px 16px 12px 18px;
    border-radius: 0.5em;
    border-top: 1px solid #80e3cc;
    border-bottom: 4px solid #003e30;
    box-shadow: #252d42 0 2px 0px;
    font-size: 17px;
    display: inline-block;
    border-right: none;
    border-left: none;
    font-family: Georgia, 'Times New Roman', Serif;
    display: inline-flex;
    align-items: center;

    &:after {
      content: '';
      display: inline-block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0.4em 0 0.4em 0.7em;
      border-color: transparent transparent transparent #fff;
      margin-left: 16px;
    }

    &:hover {
      background: #01755a;
      background-image: linear-gradient(#018a6a, #01634c);
      box-shadow: none;
    }
  `

  if (props.external) {
    return (
      <a className={cont} href={props.to}>
        {props.children}
      </a>
    )
  }

  return (
    <Link className={cont} to={props.to}>
      {props.children}
    </Link>
  )
}

export default BtnLink
