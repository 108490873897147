import React from 'react'
import { css } from 'emotion'

const Heading = props => {
  const cont = css`
    font-size: 36px;
    margin-bottom: 10px;
  `
  return <h2 className={cont}>{props.children}</h2>
}

export default Heading
